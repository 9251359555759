import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "image.png" }) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return (
      <div
        style={{
          height: "40vh",
          width: "100%",
          marginBottom: "-10px",
          backgroundColor: "darkgrey",
        }}
      >
        .
      </div>
    )
  }

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  // return (
  //   <img
  //     srcSet={data.placeholderImage.childImageSharp.fluid.srcSet}
  //     loading="eager"
  //     alt="singing"
  //     style={{ height: "40vh", width: "100%", marginBottom: "-10px" }}
  //   />
  // )
}

export default Image
