import React from "react"
import styled from "styled-components"

const CONTACT_URL =
  // process.env.GATSBY_PAYMENT_URL + "/contact-form" ||
  process.env.CONTACT_FORM_URL ||
  console.log("!!!!!!!!!!! contact") ||
  "/contact-form"
// console.log("contact", CONTACT_URL)
const Button = styled.button`
  background-color: rgb(79, 90, 187);
  padding: 10px 20px;
  margin: 10px auto;
  border-radius: 5px;
  /* border: 1.5px solid white; */
  font-size: larger;
  border: none;
  color: white;
  width: 250px;
  box-shadow: 0 0 5px 0 whitesmoke;
  text-transform: uppercase;
  &:hover {
    box-shadow: 0 0 6px 2px whitesmoke;
  }
`
const Input = styled.input`
  display: block;
  padding: 8px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  height: 35px;
  font-size: large;
  max-width: 300px;
  margin: 8px auto;
  background-color: #ffffea;
  border: none;
  box-shadow: 0px 2px 4px -1px #424242;
  border-left: 5px #c50000 inset;
  &:valid {
    background-color: #ececec;
    border-left-color: transparent;
  }
  @media screen and (min-width: 450px) {
    max-width: 500px;
    padding: 10px 0;
    margin: 0;
    width: 100%;

    /* border: 2px red inset; */
  }
`
const Label = styled.label`
  display: block;
  padding: 8px 0;
  text-align: center;
  // color: white;
  width: 100%;
  max-width: 400px;
  margin: auto;
  // display: block;
  text-transform: capitalize;
  @media screen and (min-width: 450px) {
    max-width: 500px;
    // width: 100%;
  }
`
const TextAreaLabel = styled(Label)`
  height: 150px;
  width: 100%;
  margin: 0 auto;
  & textarea {
    width: 95%;
    height: 100%;
    text-align: center;
    font-size: larger;
    padding: 5px;
  }
`
const FirstLastDiv = styled.div`
  min-width: 270px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  & input {
    /* border-left: 2px red inset; */
    width: 85%;
    // max-width: 135px;
    margin: 0;
    /* background-color: #7c6464; */
  }
  @media screen and (min-width: 450px) {
    max-width: 500px;
    & input {
      min-width: 97%;
      margin: auto;
      // border: 2px red inset;
    }
  }
`

const ContactFormDiv = styled.div`
  // border: 5px solid white;
  padding: 15px;
  margin: 50px auto;
  // width: fit-content;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 450px) {
    max-width: 600px;
    width: 100%;
  }
`
const ContactFormContainer = styled.div`
  background-color: aliceblue;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  min-height: 100vh;
  // max-height: 750px;
`

const LabeledInput: React.FC<any> = ({ text, topText = true, ...rest }) => (
  <Label>
    {topText ? text : ""}
    <Input placeholder={!topText ? text : ""} {...rest} />
  </Label>
)
export default class ContactForm extends React.Component {
  state = {
    isOpen: false,
    isSubmitted: "",
    showButton: true,
    error: null,
    isValid: false,
    first_name: "",
    last_name: "",
    cus_email: "",
    cus_tel: "",
    message: "",
    bus_name: "",
  }
  toggleOpen = () => {
    console.log("ooo", this.state)
    this.setState({ isOpen: !this.state.isOpen })
  }
  setError = (error: string | null) => this.setState({ error })
  setIsValid = (isValid: boolean) => this.setState({ isValid })
  handleInputChange = ({
    currentTarget,
  }: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!currentTarget) return
    const { name, value } = currentTarget
    this.setState({ [name]: value })
  }
  validate = () => {
    const { first_name, last_name, cus_email, message } = this.state
    if (!first_name || !last_name) {
      this.setError("missing name")
      return false
    }
    // TODO validate email address
    if (!cus_email) {
      this.setError("missing email")
      return false
    }
    if (!message) {
      this.setError("missing message")
      return false
    }
    // console.log("!!!!!!!!! ", this.state)
    // this.props.nextHandler(this.state)
    this.setState({ error: "", isValid: true })
    return true
  }
  // ??? trycatch
  sendContactForm = async (data: any) => {
    console.log("!!! ", data)
    const response = await fetch(CONTACT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "email-token": "kns205001220",
      },
      body: JSON.stringify(data),
    })
    console.log(data)
    return response.json()
    // console.log("rj", rsj)
    // return rsj
    // return { status: "ok" }
  }

  handleSubmit = () => {
    this.setState({ showButton: false })

    if (this.validate()) {
      console.log("submit", this.state)
      const {
        first_name,
        last_name,
        cus_email,
        cus_tel,
        message,
        bus_name,
      } = this.state
      const data = {
        first_name,
        last_name,
        cus_email,
        cus_tel,
        bus_name,
        message,
      }
      this.sendContactForm(data)
        .then(retData => {
          console.log(retData)
          if (
            retData &&
            (retData.mstatus === "sent" || retData.statusText === "Accepted")
          ) {
            this.setState({ isSubmitted: "Thank you, we'll be in touch" })
          } else {
            this.setState({
              isSubmitted:
                "Something went wrong, please try again or email us directly at info@karanook.com",
            })
            console.log("Errrr ")
          }
          this.setState({ isOpen: false, showButton: true })
        })
        .catch(console.log)
    } else {
      this.setState({ showButton: true })
      console.log("not valid")
    }
  }

  render() {
    if (!this.state.isOpen) {
      return (
        <>
          {this.state.isSubmitted && (
            <p className="error-message">{this.state.isSubmitted}</p>
          )}
          <Button id="ContactForm" onClick={this.toggleOpen}>
            contact us
          </Button>
        </>
      )
    }
    return (
      <ContactFormContainer>
        <ContactFormDiv id="ContactForm">
          {/* <form> */}
          <FirstLastDiv>
            <LabeledInput
              name="first_name"
              text="first"
              type="text"
              onChange={this.handleInputChange}
              required
              autoFocus
            />
            <LabeledInput
              name="last_name"
              text="last name"
              type="text"
              onChange={this.handleInputChange}
              required
            />
          </FirstLastDiv>
          <LabeledInput
            name="cus_email"
            text="email"
            type="email"
            onChange={this.handleInputChange}
            required
          />
          <LabeledInput
            name="cus_tel"
            text="phone"
            type="tel"
            onChange={this.handleInputChange}
            placeholder="(if you'd prefer a call)"
          />
          <LabeledInput
            name="bus_name"
            text="business name"
            type="text"
            onChange={this.handleInputChange}
          />
          <TextAreaLabel>
            message:
            <textarea
              name="message"
              onChange={this.handleInputChange}
              placeholder="let us know about your space and equipment"
            />
          </TextAreaLabel>
          <br />
          <br />
          {this.state.error && (
            <p className="error-message">{this.state.error}</p>
          )}
          {/* {this.state.showButton ? (
          <Button>loading</Button>
        ) : ( */}
          <Button onClick={this.handleSubmit}>send</Button>
          {/* )} */}

          {/* </form> */}
        </ContactFormDiv>
      </ContactFormContainer>
    )
  }
}
