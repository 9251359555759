import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
// import Cards from "../components/cards"
// import Button from "../components/button/button"
import Contact from "../components/contact"
import Bodycontent from "../components/bodyContent"
// import { motion } from "framer-motion"
const StyledDiv = styled.div`
  width: 100%;
  margin: 0;
  // margin-bottom: 1.5rem;
  // border: red inset 3px;
  position: relative;
  box-shadow: 0 3px 4px 0px #a54d95ee;
  overflow: hidden;
  // & img {
  //   // border: red inset 3px;
  //   margin-bottom: -10px;
  // }
  // transform: scaleY(0.5) translateY(-50%);
  // transition-duration: 1500ms;
`
// const StyledButton = styled(Button)`
//   position: absolute;
//   left: 25%;
//   top: 30%;
//   height: 55px;
// `
// const StyledButton2 = styled(StyledButton)`
//   left: 50%;
//   @media (max-width: 600px) {
//     top: 70%;
//   }
// `
// const AminatedDiv = styled(motion.div)`
//   background-color: purple;
//   height: 600px;
//   width: 600px;
// `
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledDiv>
      <Image />
      {/* <StyledButton>u pay us monies</StyledButton> */}
      {/* <StyledButton2>now now</StyledButton2> */}
    </StyledDiv>
    <Bodycontent />
    {/* <Cards /> */}
    <br />
    <Contact />
    <br />
  </Layout>
)

export default IndexPage
