import React from "react"
import styled from "styled-components"

const Div = styled.div`
  // min-height: 40vh;
  height: 12vh;
  width: 100%;
  background-color: #e2b0ec;
  margin: 0;
  padding: 0;
  & > P {
    padding: 0 15px 25px 15px;
  }
`
export default function Bodycontent() {
  return (
    <Div>
      <br />
      <p>
        {/* <strong>making party party fun time since 1969</strong>&ensp; Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit ipsam
        sunt quidem. Recusandae, enim consectetur labore neque tempore laborum
        officia consequatur repudiandae necessitatibus repellat commodi
        veritatis optio fuga aut accusamus illum mollitia itaque perspiciatis. */}
      </p>
    </Div>
  )
}
